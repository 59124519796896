import React, { useEffect, useMemo, useState } from 'react'
import { PageProps } from 'gatsby'
import SVG from 'react-inlinesvg'

import { EmailNotificationLayout } from '../../../components/email-notification'
import { varClass } from '../../../components/shared'

export default function Invoice(props: PageProps) {

  const [type, setType] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setType(params?.get('type') ?? '')
  }, [])

  const renderStarterPlan = () => <>
    <div className='column column-fill column-space-between h-100px'>
      <div className='column gap-1'>
        <SVG src='/images/email-notifications/invoice/starter-plan-icon.svg' />
        <div className='text-body-background text-bold text-larger'>Starter</div>
      </div>
      <div className='text-dark text-boldest text-large'><span className='text-bold'>$</span>12</div>
      <div className='text-dark text-bold text-7'>Your current plan</div>
    </div>
  </>

  const renderAccountInfo = (textColor = 'text-body-background') => <>
    <div className='column column-middle gap-1 w-120'>
      <label className={varClass({
        'text-6-5 text-bold': true,
        [textColor]: !!textColor,
      })}>Account name</label>
      <div className='account-input'>
        <span className='text-dark text-8'>Johannes Nielsseon</span>
      </div>
    </div>
  </>

  const render4BlackDots = () => <div className='row row-middle row-fill'>
    {Array.from({ length: 4 })
      .map((_, index) =>
        <SVG key={index} src='/images/email-notifications/invoice/mini-black-cycle-icon.svg' />)}
  </div>


  const renderExpireInfo = () => <>
    <div className='row row-center expire-info'>
      <SVG src='/images/email-notifications/invoice/blue-cycle-icon.svg' />
      <SVG src='/images/email-notifications/invoice/visa-icon.svg' />
      <div className='column column-fill visa-number text-bold'>
        <span
          className='text-dark row row-center'>{render4BlackDots()} {render4BlackDots()} {render4BlackDots()} 0000</span>
        <span className='expire-date'>Expiry 2/24</span>
      </div>
    </div>
  </>

  const data = useMemo(() => {
    switch (type) {
      case '2':
        return {
          title: <>Your July Invoice (#32382) is <span className='text-boldest'>overdue</span></>,
          desc: <>
            To avoid account suspension, please pay your overdue invoice. The invoice was initially due on <span
            className='text-boldest  '>[date]</span>, and it should be settled within the next seven days to keep your
            account active.
          </>,
          invoiceCard: <>
            <div className='invoice-card overdue'>
              <div className='row gap-80'>
                {renderStarterPlan()}
                {renderAccountInfo('text-dark')}
              </div>
            </div>
          </>,
          btnLabel: 'View Subscription settings',
        }
      case '3':
        return {
          title: <>Your credit card is about to expire </>,
          desc: <>
            <span className='text-boldest'>To ensure your account remains active, please update your credit card or payment method.</span><br />
            The credit card linked to your Heylink account will soon expire.
          </>,
          invoiceCard: <>
            <div className='invoice-card expire'>
              {renderExpireInfo()}
            </div>
          </>,
          btnLabel: 'Edit payment method ',
        }
      default:
        return {
          title: <>Your <span className='text-boldest'>July (#32382)</span> Invoice is ready!</>,
          desc: <>
            <span className='text-boldest'>Payment received!</span><br />
            Your subscription has been successfully charged, and your invoice is ready. You don't need to do anything
            else.
          </>,
          invoiceCard: <>
            <div className='invoice-card ready'>
              <div className='row gap-80'>
                {renderStarterPlan()}
                {renderAccountInfo()}
              </div>
            </div>
          </>,
          btnLabel: 'View invoice',
        }
    }
  }, [type])


  return <>
    <EmailNotificationLayout>
      <div className='invoice column column-fill column-middle column-center'>
        <div className='column gap-30 column-middle column-center'>
          <h1 className='text-bold text-dark text-largiestest text-center m-y-0 max-w-374'>{data.title}</h1>
          {data.invoiceCard}
          <p className={
            varClass({
              'text-dark text-center m-y-0 max-w-333': true,
              'text-smaller': type === '2' || type === '3',
              'max-w-312': type === '3',
            })
          }>
            {data.desc}
          </p>
        </div>
        <div className='m-t-20'>
          <button className='btn btn-primary p-3 text-smaller text-bold'>{data.btnLabel}</button>
        </div>
      </div>
    </EmailNotificationLayout>
  </>
}